@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 100% 0;
  }
}

h1 {
  margin: 10px 0;
}

h2 {
  color: #ccc;
  font-family: Open Sans, Arial, Helvetica, sans-serif;
}

h3 {
  color: #ccc;
  text-shadow: 2px 12px 14px #000;
  margin: 0;
  font: 16px / 24px Open Sans, Arial, Helvetica, sans-serif;
}

a {
  color: #008fb0;
}

.floatLeft {
  float: left;
}

.clear-both {
  clear: both;
}

p {
  margin: 20px 20px 30px;
}

#content {
  height: 100vh;
}

.long-text {
  margin-bottom: 100px;
}

#jh-page {
  background: linear-gradient(#3330, #0009) no-repeat;
  width: 72%;
  height: 100vh;
  margin: 153px 50% 0;
  padding: 0;
  position: absolute;
  transform: translate(-50%, -53px);
}

.instagram {
  text-align: center;
  z-index: -1;
  position: absolute;
  top: 0;
}

.instagram img.mini {
  width: 10px;
}

@media (max-width: 900px) {
  #jh-page {
    width: 90%;
    height: auto;
  }
}

.view-frame {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  stroke-dasharray: 30;
  opacity: .8;
  height: 50px;
  margin: 50%;
  animation: 1.5s linear infinite dash;
}

.loader.hidden {
  display: none;
}

@keyframes dash {
  from {
    stroke-dashoffset: 30px;
  }
}

.black-and-white {
  filter: grayscale();
  transition: filter .5s;
}

.black-and-white:hover {
  filter: none;
}

/*# sourceMappingURL=index.9904d38c.css.map */
